.services-list{
  height:calc(100vh - 210px);

  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #004288;
    border-color: #004288;
  }  

  .react-bs-container-body {
    display:block;
    box-sizing: border-box;
    height:calc(100% - 55px ) !important

  }

  .btn-rota-detay{
    display:flex;
    margin:auto;
  }

  .servicenameCol div{
    display:none !important;
  }

}

.filterLabel{
  width:170px;
  margin: 0px;
  display: flex;
  align-items: center;  
  font-weight: 500;
}
.filterLabelYolcuRapor{
  width:120px;
  margin: 0px;
  display: flex;
  align-items: center;  
  font-weight: 500;
}

.rotalabel{
  margin-bottom:5px;
  font-weight: 500;
}
.filterSelect{
  width:200px;
}
.filterSelectYolcuRaporu{
  width:180px;
}
.filterLabelServisTarihSaat{
  width:140px;
  margin: 0px;
  margin-right:20px;
  display: flex;
  align-items: center;  
  font-weight: 500;
}
.firstSelect{
  margin-right: 5px;
}

.react-bs-container-footer .table-bordered th{
  border:0px !important;
}
.btn-summary-excel{
  width:200px;
  margin-right:10px;
}

.services-list .react-bs-table-tool-bar{
  display:none !important;
}