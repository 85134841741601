.logoWrapperID {
  display: block;
  width: 364px;
  height: 156px;
  top:10px;
  margin: 0;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  transform: translate(0, 100%);

  .logoID {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      margin-left:auto;
      margin-right:auto;
  }

  .tmpId {
    display: block;
    position: relative;
    width: 364px;
    height: 156px;
    display:none;
  }
  
  

}


@media screen and (max-height: 600px) {
  .logoWrapperID {
      top:15px !important;
      transform: translate(0, 3%) !important;
      width:auto !important;
      height:auto !important;
      max-height: 94px !important;
      max-width:364px !important;
      .logoID{
        width:285px !important;
        height:122px !important;
      }
    

  }    

  .welcomeWrapper {
      transform: translate(0, 200%) !important;
  }

  .loginForm {
      transform: translate(0, 10%) !important;
  }




}
