.tableServisler td{
  vertical-align: middle !important;
}

.switch.btn{
  width:140px !important;
}

.servisTableWrapper{
  height:calc(100% - 70px);
  overflow: auto;
}

.servisTable{
  // height:calc(100% - 70px) !important;
  overflow: auto;
}
.servisSurucuTable{
  height:100%;
  overflow: auto;

}

.btn-change-reservation{
  width:140px !important;
  height:36px !important;
  line-height: 36px !important;
  padding:0px !important;
  margin-top:10px;
  font-size:17px !important;
}