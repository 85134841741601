* {
  margin: 0px;
  padding: 0px;
  outline: none !important;
  /* font-size:0.9rem !important; */
}

.noBodyImage{
  background-image: none !important;
}

.leftID{
  position:relative;
  float:left;
  display:block;
  width:120px;
  height:100vh;
  background:#004288;
  background-image:url(../../assets/images/overlays/06.png);
  background-repeat:repeat;
  

}

.mobileWrapper{
  position:relative;
  display:block;
  width:100%;
  height:100vh;
  background:#fff;
  padding-top:60px;
}

.rightID{
  position:relative;
  float:right;
  width: calc(100% - 120px);
  height: 100vh;
  box-sizing: content-box;
  background:#f3f3f3;
  box-shadow: inset 5px 0 5px -2px #888;
}