.toolbarTitle{
  display: flex;
  align-items: center;
  margin: 0px;  
}
.passengers-list{
  height:calc(100vh - 210px);

  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #004288;
    border-color: #004288;
  }  

  .react-bs-container-body {
    display:block;
    box-sizing: border-box;
    height:calc(100% - 55px ) !important
  }

  .btn-rota-detay{
    display:flex;
    margin:auto;
  }

  .displaynameCol div{
    display:none !important;
  }

}

.filterLabel{
  width:170px;
  margin: 0px;
  display: flex;
  align-items: center;  
  font-weight: 500;
}

.rotalabel{
  margin-bottom:5px;
  font-weight: 500;
}
.excel-table{
  display:none;
}

.btn-excel{
  margin-right:5px;
  background-color: #004288 !important;
  border-color: #004288 !important;  

  &:hover{
    background-color:#0351a4 !important;
    border-color:#0351a4 !important;
  }
}