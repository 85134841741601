.breadcrumbLayer{
  height: 100%;
}
.breadcrumbTitle{
  line-height: 50px;
  padding-left:10px;
}
.mobileWrapper {
  .mobileMenu > li:first-child > span {
    padding-top: 8px !important;
    padding-right: 8px !important;
    padding-left: 4px !important;
  }
}