.leftMenuWrapper{
  padding: 0px;
  box-sizing: border-box;
  height: calc(100% - 95px);
  overflow: hidden;
  overflow-y: auto;

  .leftMenuID{
    position: relative;
    list-style: none;
    margin-top:10px;
  
    li{
      margin-top:15px;
    }
  }  


  .selectedMenu{
    border-left-width: 3px;
    border-left-color: #fff;
    border-left-style: solid;
    padding-top:3px;
  }  

  .menu-link{
    background:none;
    border:none;
    color:#ebebeb;
    text-decoration: none;
    display:block;
    margin-left:auto;
    margin-right:auto;
    
    &:hover{
      color:#ffffff;
    }
  }

  .color-white{
      color:#ffffff !important;
  }  


  .menu-text{
      display:block;
      text-align: center;
      font-size:13px !important;
      margin-top:5px;
      padding-left:5px;
      padding-right:5px;
  }
  .menu-img{
      display:block;
      width:30px;
      height:30px;
      margin-left:auto;
      margin-right:auto;
      display:block;
  }  

  .navmenuMask{
    position:absolute;
    width: 100%;
    height:100%;
    background:rgba(0,0,0,0.2);
    z-index:1;
  }

  .maskShow{
    display:block;
  }
  .maskHide{
    display:none;
  }


}

