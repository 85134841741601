.bus-list{
  height:calc(100vh - 185px) !important;

  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #004288;
    border-color: #004288;
  }  

  .react-bs-container-body {
    display:block;
    box-sizing: border-box;
    height:calc(100% - 55px ) !important
  }

  .btn-rota-detay{
    display:flex;
    margin:auto;
  }

  .displayCol div{
    display:none !important;
  }

}

.filterLabel{
  width:170px;
  margin: 0px;
  display: flex;
  align-items: center;  
  font-weight: 500;
}

.filterLabelYolcuIslemleri{
  width:65px;
  margin: 0px;
  display: flex;
  align-items: center;  
  font-weight: 500;
}

.rotalabel{
  margin-bottom:5px;
  font-weight: 500;
}
.userbuttons{
  color:#004288 !important;
}

.passengersPageWrapper{
  display:block;
  width:100%;
  height:100%;
}
.spWrapper{
  display: block;
  width:100% !important;
  height:100% !important;
}

.bus-list colgroup col:first-of-type {
  width: 45px !important;
}

.minibuttons{
  width:53px;
}
.row-select-class{
  background-color:#004288;
  color:#fff !important;

  &:hover{
    color:#fff !important;
  }
}

.borderRight{
  border-right:1px solid #dddddd;
}
.toolbar-sep{
  display: inline-block;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  width:1px;
  margin-right:11px;
}
.colorOrange{
  color: orangered !important;
}
.editFilter{
  max-width:200px;
}

.rotaLeft{
  text-align:center;
  font-weight: bold;
}
.rotaRight{
  text-align:center;
  font-weight: bold;
}
.passwordInfo{
  font-size:17px;
  color:#111;

}

.SplitterPanelLeft {


  .react-tabs__tab-list{
    padding:0px !important;
    // border-bottom:none !important;
    border-bottom: 1px solid #ddd;
    margin-bottom:0px;
  }

  .react-bs-table-bordered {
    border: 1px solid #ddd;
    border-radius: 5px;
    border-top-left-radius: 0px;
    background: #fff;
  }  

  .react-tabs__tab--selected{
    color:#004288;
    font-weight: 500;
  }
}

.padding15{
  padding:15px;
}
.columnInfo{
  font-size:17px !important;
  font-weight: normal;
}

.form-label{
  font-weight: 500;
}
.address-line{
  margin-bottom:0px;
}

.addressRow{
  position: relative;
  display: flex;
  overflow:auto;
  box-sizing: border-box;
  max-height: 240px;
}
.address-row{
  margin-bottom:10px;
}
.address-form label{
  font-weight: 500;
  color:#004288;
}
.margin-right-5{
  margin-right:5px;
}

.react-bootstrap-table-page-btns-ul{
  margin:0px;
}

.react-bs-table-pagination div:first-child{
  margin-top:-5px !important;
}