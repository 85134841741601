.btnEDMSDefault {
  background: rgba(3,81,164,0.7) !important;
  height: 50px !important;
  font-size: 130% !important;
  color: #ffffff !important;
  border-color: rgba(0,66,136,0.8) !important;
  transition: box-shadow .2s;

  &:hover{
    box-shadow: 0px 0px 30px 4px rgba(73,129,188,.6) !important;
  }
}

