.otherPassengersMainWrapper{
  height:100% !important;
}

.otherPassengerTitleWrapper{
  display: flex;
  align-items: center;
}
.otherPassengerTitle{
  margin-bottom:0px;
}

.passengers-list-others{
  height: calc(100vh - 165px) !important;

  .react-bs-container-body{
    height:calc(100% - 40px) !important;
  }

}

.otherPassengerContainer{
  display: block;
  width: 100%;
  height:100%;
}