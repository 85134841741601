@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
* {
  margin:0px;
  padding:0px;
  outline:none !important;
}
body{
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif !important;
  color:#212121 !important;
}

.shadowfilter {
  -webkit-filter: drop-shadow(0px 0px 0px rgba(255,255,255,0.80));
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;

  &:hover{
    -webkit-filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.8));
  }
}

.clearfix {
  clear: both;
}

.buttonDefault{
  background-color: transparent !important;
  border:none;
  font-weight: lighter;
}

.elementWrapper{
  display:block;
  clear:both;
}

.warning-img {
  position: absolute;
  right: 10px;
}

/*Loader*/
.edms-loading-screen {
	background-color: #004288;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	text-align: center;
	top: 0;
  z-index: 99999;

  .edms-loading-icon {
    display: table;
    height: 100%;
    position: absolute;
    width: 100%;

    .edms-loading-inner {
      display: table-cell;
      vertical-align: middle;
  
      .edms-load {
        border-radius: 50%;
        margin: auto;
        position: relative;
        animation: fadeIn 1s infinite alternate;  
    
        &:before{
          background-color: transparent;
          border-radius: 50%;
          bottom: 0;
          content: "";
          display: block;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition-duration: 0.4s;
          transition-property: opacity;
          transition-timing-function: linear;
          z-index: 2;
          opacity: 1;
          visibility: visible;
        }
    
        &:after{
          font-size: 1.8em;
          font-weight: 700;
          line-height: 57px;
          color: #FFF;
        }
    
      }    
    }    

  }  

}
  
@keyframes fadeIn {
  from {
  opacity: 0;
  }
}

@keyframes fadeInMain{
  from {
    opacity:0;
    z-index: 0;
    display: none;
  }
  to {
    opacity: 1;
    z-index: 99999;
    display: block;
  }
}
@keyframes fadeOutMain{
  from {
    opacity:1;
    z-index: 99999;
  }
  to {
    opacity: 0;
    z-index: 0;
  }
}

.loading-show{
  animation: fadeInMain 1s;
  animation-iteration-count:1;
  
}
.loading-hide{
  opacity: 0;
  z-index: 0;
  animation: fadeOutMain 1s;
  animation-iteration-count:1;
}

  /* End Loader*/
  .hidden{
    display:none;
  }

 .table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f9f9f9 !important;
}