.mainMenu .k-menu{
  
}

.mainMenu.k-menu > .k-item  .k-link {
  
}

.mainMenuIcon.k-image{
  margin-left:6px !important;
  margin-right:0px !important;
}

.mainMenu .k-menu .k-item, .k-menu.k-header{
  border-color:unset !important;
}

.mainMenu .k-menu .k-item, .k-menu-scroll-wrapper .k-item, .k-menu-scroll-wrapper.horizontal>.k-item, .k-popups-wrapper .k-item, .k-popups-wrapper.horizontal>.k-item, .k-widget.k-menu-horizontal>.k-item {
  position: relative;
  float: left;
  border-style: unset !important;
  border-width: unset !important;
  vertical-align: top;
  zoom: 1;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.k-menu .k-link.k-state-active {
 color: #fff !important;
}
.k-menu-group .k-state-hover>.k-link{
  color:#ffffff !important;
}






.k-dropdown .k-state-focused, .k-filebrowser-dropzone, .k-list>.k-state-hover, .k-mobile-list .k-edit-label.k-check:active, .k-mobile-list .k-item>.k-label:active, .k-mobile-list .k-item>.k-link:active, .k-mobile-list .k-recur-view .k-check:active, .k-pager-wrap .k-link:hover, .k-scheduler .k-scheduler-toolbar ul li.k-state-hover, .k-splitbar-horizontal-hover:hover, .k-splitbar-vertical-hover:hover, .k-state-hover, .k-state-hover:hover{
  background-color:#0351a4 !important;
  color:#fff !important;
}

.mainMenu .k-menu .k-link.k-state-active {
  background-color: #0351a4;
  color: #fff !important;
}

.mainMenu{
  background-color:transparent !important;
  border:none !important;
}
.mainMenu li{
  max-height: 50px !important;
}

.mainMenu .k-active-filter, .k-state-active, .k-state-active:hover, .k-tabstrip .k-state-active {
  background-color: #0351a4 !important;
  /* border:none !important;; */
  color: #fff;
}




.mainMenu li.k-item.k-state-default.k-first.k-last.k-state-border-down{
  background:unset !important;
}


.k-splitbar.k-state-focused{
  background-color:#0351a4 !important;
  border-color:#0351a4 !important;
}
.k-draghandle.k-state-selected:hover,.k-ghost-splitbar-horizontal,.k-ghost-splitbar-vertical,.k-list>.k-state-highlight,.k-list>.k-state-selected,.k-marquee-color,.k-panel>.k-state-selected,.k-scheduler .k-scheduler-toolbar .k-state-selected,.k-scheduler .k-today.k-state-selected,.k-state-selected,.k-state-selected:link,.k-state-selected:visited,.k-tool.k-state-selected{
      color:#212121;
      /* background-color:#0351a4 !important;
      border-color:#0351a4 !important; */
      background-color:#ededed !important;
      border-color:#ededed !important;

}

.brown-tooltip + .tooltip > .tooltip-inner {background-color: #212121 !important;}
.brown-tooltip + .tooltip > .tooltip-arrow { border-bottom-color:#212121 !important; }

.tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.bs-tooltip-left .arrow::before {
  border-left-color: #212121;
}
.tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.bs-tooltip-right .arrow::before {
  border-right-color: #212121;
}
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #212121;
  border-radius: .25rem;
}

.jconfirm .jconfirm-box .jconfirm-buttons button.btn-red {
  background-color: #0351a4 !important;
  color:#212121 !important;
}
.jconfirm.jconfirm-modern .jconfirm-box div.jconfirm-title-c{
  color:#212121 !important;
}

.grid-pane .pane-content{
  height: 100% !important;
}
.grid-pane .k-grid-content.k-auto-scrollable{
  height: calc(100% - 34px);
}
.k-pager-numbers .k-state-selected {
  color:#fff;
  background-color:#0351a4 !important;
  border-color:#0351a4 !important;
}
.k-tabstrip-wrapper{
  height: 100%;
}
.k-tabstrip-items{
  background:#fff;
}
.k-tabstrip-wrapper .k-content{
  height:calc(100% - 50px);
  background-color:#fff !important;
}
.k-tabstrip-items .k-state-active .k-link{
  color:#fff !important;
}
.k-tabstrip-items .k-state-hover .k-link{
  color:#fff !important;
}
.k-tabstrip-items .k-state-hover{
  box-shadow: 
      inset 0px -11px 18px -10px #0351a4; 

}

.k-tabstrip-items .k-state-default{
  background-color:#0351a4 !important;
  color:#fff !important;
}
.k-tabstrip-items .k-state-active{
  background-color:#0351a4 !important;
  color:#fff !important;
}
.k-tabstrip-items .k-state-default .k-link{
  color:#fff !important;
}

.rightSplitter .k-tabstrip.k-header {
  background-color: unset !important;
}
.k-tabstrip>.k-content {
  padding: 0.2em !important;
  padding-top:10px !important;
}

.k-window, .k-window-titlebar {
  border-radius: 10px !important;
}

/* .k-upload-selected {
  display: none !important;
}

.k-clear-selected{
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
  margin-top: 3px !important;
  border-radius: 10px !important;
  margin-bottom: 8px !important;    
} */

.k-widget.k-window.k-dialog.k-confirm{
  width:300px;
}
.k-widget.k-window.k-dialog.k-confirm .k-dialog-titlebar{
  display:none;
}

.k-upload-files {
  overflow-y: auto !important;
  max-height: 200px !important;
}
.k-window-title{
  font-weight: bold;
}
.k-widget.k-upload{
  border-radius:5px !important;
}
