* {
  margin: 0px;
  padding: 0px;
  outline: none !important;
}

body {
  background-image: url(../../assets/images/vdbg1.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  font-family: 'Roboto', sans-serif !important;
  color:#ffffff !important;
  font-weight:300 !important;
}

.mainID {
  display: block;
  height: 100%;
  width: 100%;
  // background-image: radial-gradient(circle, rgba(56, 56, 56, 0.8) 0, #282827 120%);
  background-position: 0px top,calc(100% - 10px) bottom,center center;
  background-repeat: no-repeat;
  // opacity: 0.9;
  min-width: 320px;
  position: fixed;
}

.overlayID{
  position:fixed;
  display:block;
  width: 100%;
  height:100%;
  // background:url(../../assets/images/overlays/05.png);
  // background-size:3px 3px;
  background-repeat:repeat;




  .welcomeWrapper {
      display: block;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      max-width: 365px;
      transform: translate(0, 915%);
  }    

  .welcomeWrapper h1 {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .45em solid white; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .11em; /* Adjust as needed */
    animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
    position: relative;
    font-size:18px;
  }    

  /* The typing effect */
  @keyframes typing {
      from {
          width: 0
      }

      to {
          width: 100%
      }
  }

  /* The typewriter cursor effect */
  @keyframes blink-caret {
      from, to {
          border-color: transparent
      }

      50% {
          border-color: white;
      }
  }

  .loginForm{
    position: relative;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    transform: translate(0, 70%);

    .loginFormElements {
      position: relative;
      width: 365px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      padding: 0px;
      margin-top: 30px;

      li {
        list-style: none;
        list-style-type: none;
        margin-top: 7px;
        width: 100%;
        display: table;
      }
    } 
    
    .edit {
        height: 40px !important;
        /*line-height: 40px !important;*/
        font-size: 120% !important;
        color: #252525 !important;
        font-weight: 300;
    }

    .select{
        height: 40px !important;
        /*line-height: 40px !important;*/
        font-size: 120% !important;
        color: #252525 !important;
        font-weight: 300;    
    }    

    
    .loginAdditionalWrapper {
        font-size: 100%;

        div.float-left{
          padding-left:20px;
        }
    }


    .lostPassword{
        color:#fff;
        transition: text-shadow .2s;
    }
    .lostPassword:hover {
        color: #ffffff !important;
        text-shadow: 2px -1px 13px #fff;
        text-decoration: none;
    }


    .lostPasswordWindow{
        padding-bottom:0px;

    }
    .editLostPassword{
        max-width:395px;
        padding:2px 12px !important;
    }
    .lostPasswordInfo {
        font-size: 17px;
        line-height: 25px;
        color: #555555;
    }    



  }



}



@supports (-webkit-filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.8))) {
  .btnEDMSDefault {
      transition: -webkit-filter .2s;
      
      &:hover{
        box-shadow: none !important;
        /*-webkit-filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.8));*/
        -webkit-filter: drop-shadow(0px 0px 8px rgba(4, 21, 34, 0.7));
      }
  }


  .lostPassword {
      transition: -webkit-filter .2s;

      &:hover{
        text-shadow: none !important;
        -webkit-filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.8));
      }
  }

}








@media screen and (max-height: 600px) and (orientation: landscape) {
  .loginCopyrightID{
      position: relative;
      display: block;
      width: 100%;
      bottom: 0px;
      margin-top: 50px;        
  } 
}

@media screen and (max-height: 767px) {
  .logoWrapperID {
      top:15px;
      transform: translate(0, 10%);
  }

  .welcomeWrapper {
      transform: translate(0, 300%) !important;
  }

  .loginForm {
      transform: translate(0, 20%) !important;
  }

  .freeSpace {
      height: 10px;
  }
}

@media screen and (max-height: 600px) {
  .logoWrapperID {
      top:15px;
      transform: translate(0, 3%);
  }    

  .welcomeWrapper {
      transform: translate(0, 200%);
  }

  .loginForm {
      transform: translate(0, 10%);
  }

  .logoID{
      height:94px;
  }
}

.bgwhite{
  background-color:#fff;
}
.resetpageInfo{
  border-radius: 5px;
  padding:20px !important;
  text-align:center;
}
.resetpageTitle{
  color:#004288;
  text-align:center;
}

.resetpageMessage{
  font-size:18px;
  color:#000;
  margin-top:20px;
  font-weight: 500;
}

.btn-su{
  background-color:#004288 ;
  border-color:#004288;
  color:#fff !important;

  &:hover{
    background-color:#0351a4;
    border-color:#0351a4;
  }
}
