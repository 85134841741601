.mobileTopLayer .k-dropdown, .mobileTopLayer .k-dropdown-wrap{
  border-radius: 5px !important;
  height: 33px !important;
}
.mobileTopLayer .k-input{
  line-height: 31px !important;
}

.mobileTopLayer .k-state-default>.k-select {
  line-height: 33px !important;
}

.mobileTopLayer{
  position:fixed;
  top:0px;
  display:block;
  width:100%;
  height:70px;
  background:#004288;
  background-image:url(../../assets/images/overlays/06.png);
  background-repeat:repeat;
  box-shadow: 0 3px 11px 0 #c4c4c4;
  z-index:2;  

  .menuWrapper {
    margin-top:10px;
  }
  
}



.mobileMenuIcon{
  margin-left:5px;
  margin-right:5px;

  &:before{
    position: relative;
    top: 4px;
    font-size:26px;
  }
}

.mobileMenu > li:first-child > span{
  padding-top:8px !important;
  padding-right:8px !important;
}

.mobileMenuIcon.k-image{
  margin-left:6px !important;
  margin-right:0px !important;
}

.mobileMenu .k-menu .k-item, .k-menu.k-header{
  border-color:unset !important;
}

.mobileMenu .k-menu .k-item, .k-menu-scroll-wrapper .k-item, .k-menu-scroll-wrapper.horizontal>.k-item, .k-popups-wrapper .k-item, .k-popups-wrapper.horizontal>.k-item, .k-widget.k-menu-horizontal>.k-item {
  position: relative;
  float: left;
  border-style: unset !important;
  border-width: unset !important;
  vertical-align: top;
  zoom: 1;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.mobileMenu .k-menu .k-link.k-state-active {
  background-color: #0351a4;
  color: #fff !important;
}

.mobileMenu{
  background-color:transparent !important;
  border:none !important;
}
.mobileMenu li{
  max-height: 50px !important;
}

.mobileMenu .k-active-filter, .k-state-active, .k-state-active:hover, .k-tabstrip .k-state-active {
  background-color: #0351a4 !important;
  /* border:none !important;; */
  color: #fff;
}

.mobileMenu .k-icon.k-i-arrow-s{
  display:none;
}


.mobileMenu li.k-item.k-state-default.k-first.k-last.k-state-border-down{
  background:unset !important;
}

.k-grid-header .k-header>.k-link, .k-header, .k-treemap-title {
  color: #fff !important;
}

.mobileWrapper .mobileMenu > li:first-child > span {
  padding-top: 8px !important;
  padding-right: 13px !important;
  padding-left: 13px !important;
}