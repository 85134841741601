.toolbarTitle{
  display: flex;
  align-items: center;
  margin: 0px;  
  font-size:14px;
}
.passengers-list-driver{
  height:100% !important;

  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #004288;
    border-color: #004288;
  }  

  .react-bs-container-body {
    display:block;
    box-sizing: border-box;
    height:calc(100% - 55px ) !important
  }

  .btn-rota-detay{
    display:flex;
    margin:auto;
  }

  .displaynameCol div{
    display:none !important;
  }

}

.filterLabel{
  width:170px;
  margin: 0px;
  display: flex;
  align-items: center;  
  font-weight: 500;
}

.rotalabel{
  margin-bottom:5px;
  font-weight: 500;
}
.excel-table{
  display:none;
}

.btn-excel{
  margin-right:5px;
  background-color: #004288 !important;
  border-color: #004288 !important;  

  &:hover{
    background-color:#0351a4 !important;
    border-color:#0351a4 !important;
  }
}

.mobileWrapper .contentWrapper{
 
}

.mobileWrapper{
  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    /* padding: 6px 12px; */
    padding: 4px;
    font-size:14px;
    cursor: pointer;
  }  
  .react-tabs__tab--selected {
    font-weight: bold;
    color:#004288;
  }  
  .react-tabs__tab-list{
    border-bottom:none;
  }

  .react-bs-table-bordered {
    font-size:15px;
  }

}
