.leftID {
  
  .mainLogoWrapperID{
    padding:8px;

    .mainLogoID{
      position:relative;
      max-width: 100px;
      height: auto;
      display:block;
      margin-left:auto;
      margin-right:auto;
      top:1px;
    }
  
  }
  
}
