.bottomLogoWrapper{
  position:absolute;
  width:100%;
  bottom:10px;
  display:block;
}

.mainCopyright{
  color: #fff;
  line-height: 14px;
  font-size: 10px !important;
  text-align: left;
  margin: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 17px;
  padding-right: 10px;
}
