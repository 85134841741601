.mobileTopLayer {
  
  .mobileLogoWrapperID{
    padding:3px;
    padding-bottom:2px;

    .mobileLogoID{
      position:relative;
      max-width: 150px;
      height: auto;
      display:block;
      margin-left:auto;
      margin-right:auto;
      top:0px;
    }
  
  }
  
}
