.rightTopLayer .k-dropdown, .rightTopLayer .k-dropdown-wrap{
  border-radius: 5px !important;
  height: 33px !important;
}
.rightTopLayer .k-input{
  line-height: 31px !important;
}

.rightTopLayer .k-state-default>.k-select {
  line-height: 33px !important;
}

.rightTopLayer{
  position:relative;
  width:100%;
  display:block;
  height:50px;
  background:#e1e1e1;
  box-shadow: inset 5px 0 5px -2px #888;
  -webkit-box-shadow:inset 5px 0 5px -2px #888;
/*  -webkit-box-shadow: 3px 0 5px -2px #888;
        box-shadow: 4px 0 5px -2px #888;        */
      
}

.mainMenuIcon{
  margin-left:5px;
  margin-right:0px;

  &:before{
    position: relative;
    top: 4px;
    font-size:26px;
  }
}

.mainMenu > li:first-child > span{
  padding-top:8px !important;
  padding-right:8px !important;
}


.mainMenu.k-header{
  color:#333 !important ;
}

.raporMenu{
  padding-left:0px !important;  
}

.raporMenu.k-menu .k-item>.k-link{
  line-height: 1.5em !important;
  padding-top:13px !important;
  padding-left:15px;

}