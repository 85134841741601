.contentWrapper{
  position: relative;
  width: 100%;
  height: calc(100% - 50px);
  display: block;
  padding: 20px;  
  overflow: hidden;    
  overflow-y: auto;
  box-sizing: border-box;

}

.mobileWrapper {
  .contentWrapper{
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    padding: 20px;  
    overflow: hidden;    
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom:100px;
  }
}