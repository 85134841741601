.loginCopyrightID{
  position:absolute;
  display:block;
  width:100%;
  bottom:10px;

  p {
    text-align:center;
    color:#ffffff;
    font-size:12px;
  }
}
