.contentWrapperBlock {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: calc(100% - 65px);
  padding: 20px;
  overflow-y: auto;
  background:#fff;
  border-radius: .25rem;

  .textbox {
    position: relative;
    top: 6px;
    min-width: 100px;
    width: 100%;
    font-family: Segoe UI,Arial, Helvetica, sans-serif;
    background-color: transparent;
    font-size: 15px;
    border-radius: 5px;
    height: 100px !important;
    padding: .375rem .75rem !important;
    padding-top: 10px !important;
    margin-bottom: 20px;
  }

  .window-label {
    height: 44px;
    line-height: 44px;
    font-weight: bold;
    color: #323232;
  }

  .max-width-element {
    max-width: 500px !important;
  } 

  .edit {
    position: relative;
    min-width: 100px;
    width: 100%;
    height: 40px;
    font-family: Segoe UI,Arial, Helvetica, sans-serif;
    background-color: transparent;
    font-size: 15px;
    border-radius: 5px;
  }  

  .formcheck{
    margin-left:0px;
    top:10px;
  }

}

.btn-su{
  background-color:#004288 ;
  border-color:#004288;
  color:#fff !important;

  &:hover{
    background-color:#0351a4;
    border-color:#0351a4;
  }
}
.margin-left-10{
  margin-left:10px;
}
.requiredFieldMark{
  color:red;
}


/* Address */
.padding15{
  padding:15px;
}
.columnInfo{
  font-size:17px !important;
  font-weight: normal;
}

.form-label{
  font-weight: 500;
}
.address-line{
  margin-bottom:0px;
}

.addressRow{
  position: relative;
  display: flex;
  overflow:auto;
  box-sizing: border-box;
  min-height: 270px;
}
.address-row{
  margin-bottom:10px;
}
.address-form label{
  font-weight: 500;
  color:#004288;
}
.modalNoRecord{
  text-align:center;
  font-weight: 500;
  width:100%;
  margin-bottom:0px;
}

.adresKayitYok{
  padding-left: 15px;
  margin-top: 10px;
}



button.btn-adresyeni{
  margin-left:10px !important;
}