.lds-grid-wrapper{
  display:block;
  position: absolute;
  width: 80px;
  height: 80px;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
}
.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #004288;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}






  /* Process Loading */
  .loadingWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(255,255,255,0.5);
    z-index: 99998;
  
    &.show {
      display:block;
    }
  
    &.hide{
      display:none;
    }
    
  }

  .cssload-container{
    position:relative;
  }
    
  .cssload-whirlpool,
  .cssload-whirlpool::before,
  .cssload-whirlpool::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid rgba(3,81,164,0.76);
    border-left-color: rgb(55,55,55);
    border-radius: 974px;
      -o-border-radius: 974px;
      -ms-border-radius: 974px;
      -webkit-border-radius: 974px;
      -moz-border-radius: 974px;
  }
  
  .cssload-whirlpool {
    margin: -24px 0 0 -24px;
    height: 49px;
    width: 49px;
    animation: cssload-rotate 1150ms linear infinite;
      -o-animation: cssload-rotate 1150ms linear infinite;
      -ms-animation: cssload-rotate 1150ms linear infinite;
      -webkit-animation: cssload-rotate 1150ms linear infinite;
      -moz-animation: cssload-rotate 1150ms linear infinite;
  }
  
  .cssload-whirlpool::before {
    content: "";
    margin: -22px 0 0 -22px;
    height: 43px;
    width: 43px;
    animation: cssload-rotate 1150ms linear infinite;
      -o-animation: cssload-rotate 1150ms linear infinite;
      -ms-animation: cssload-rotate 1150ms linear infinite;
      -webkit-animation: cssload-rotate 1150ms linear infinite;
      -moz-animation: cssload-rotate 1150ms linear infinite;
  }
  
  .cssload-whirlpool::after {
    content: "";
    margin: -28px 0 0 -28px;
    height: 55px;
    width: 55px;
    animation: cssload-rotate 2300ms linear infinite;
      -o-animation: cssload-rotate 2300ms linear infinite;
      -ms-animation: cssload-rotate 2300ms linear infinite;
      -webkit-animation: cssload-rotate 2300ms linear infinite;
      -moz-animation: cssload-rotate 2300ms linear infinite;
  }
  
  
  
  
  @keyframes cssload-rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @-o-keyframes cssload-rotate {
    100% {
      -o-transform: rotate(360deg);
    }
  }
  
  @-ms-keyframes cssload-rotate {
    100% {
      -ms-transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes cssload-rotate {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes cssload-rotate {
    100% {
      -moz-transform: rotate(360deg);
    }
  }