.jconfirm.jconfirm-black .jconfirm-box{
  background:#282827 !important;
}
.jconfirm-buttons{
  float:none !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: table !important;
}    
.jconfirm-buttons .btn{
  background-color:#0351a4 !important;
  color:#fff;
}

.jconfirm-content p{
  margin-bottom:0px;
}

.jconfirm .jconfirm-box .jconfirm-buttons button.btn-default{
  color:#fff;
}
.jconfirm-buttons button.btn-orange {
  background-color: #f1c40f !important;
  color: #FFF;
  text-shadow: none;
  -webkit-transition: background .2s;
  transition: background .2s;
}

.jconfirm-buttons button.btn-danger{
  background-color: #dc3545 !important;
  color:#fff;
  text-shadow: none;
  -webkit-transition: background .2s;
  transition: background .2s;  
}

.PasswordWindowID{
  list-style: none;
}
.PasswordWindowID li{
  margin-bottom:5px;
}

.editpass{
  position: relative;
  top: 6px;
  min-width: 100px;
  width: 100%;
  height: 35px;
  font-family: Segoe UI,Arial,Helvetica,sans-serif;
  background-color: transparent;
  font-size: 15px;
  border-radius: 5px;  
  padding:5px;
  height: 40px;
  background: 0 0;
  background-color: #fff;
    color: #333;
    border-color: #c9c9c9;
    display: inline-block;
    vertical-align: middle;
    border-width: 1px;
    border-style: solid;
    overflow: visible;
    box-sizing: border-box;
    outline: 0!important;
}
.jconfirm.jconfirm-modern .jconfirm-box div.jconfirm-content {
  margin-bottom:0px;
}