div.serviceRow {
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  margin-bottom:30px;
}

div.serviceRow:last-of-type {
  margin-bottom:70px;
}

.serviceTitle{
  font-size:22px;
  font-weight: 400;
  color:#004288;
  text-align: center;
  font-style: italic;
  text-shadow: 0 0 3px #ebebeb;
}

div.serviceRow .header {
  background-color: #ebebeb;
  color: 004288;
  padding: 10px;
  font-size: 17px;
}

div.serviceRow .container {
  padding: 10px;
}

.footerP{
  margin:0px;
}
.servicesInfo{
  text-align: center;
}

.serviceInfoTitle{
  font-weight: bold;
  font-size:18px;
}

.serviceInfoContent{
  font-weight: normal;
  font-size: 17px;
}
.hidden{
  display:none;
}

.btn-sulink{
  display:block !important;
  width:150px;
  margin-left:auto;
  margin-right:auto;
  margin-top:20px;
  background-color: #004288 !important;
  border-color:#004288 !important;

  &:hover{
    background-color: #0351a4 !important;
    border-color:#0351a4 !important;
  }
}
.serviceDateSelectWrapper{
  margin-bottom:10px;
}

.btn-change-reservation{
  width:140px !important;
  height:36px !important;
  line-height: 36px !important;
  padding:0px !important;
  margin-top:10px;
  font-size:17px !important;
}