.loginForm{
  position: relative;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  transform: translate(0, 70%);

  .loginFormElements {
    position: relative;
    width: 355px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 0px;
    margin-top: 20px !important;
    min-height: 255px;

    .elementWrapper {
        position: relative;
        margin-top: 7px;
        width: 100%;
        display: table;
    }
  } 
  
  .edit {
      height: 40px !important;
      /*line-height: 40px !important;*/
      font-size: 120% !important;
      color: #252525 !important;
      font-weight: 300;
      padding-right:10px;
  }

  .select{
      height: 40px !important;
      /*line-height: 40px !important;*/
      font-size: 120% !important;
      color: #252525 !important;
      font-weight: 300;    
  }    

  .lostPassword{
      color:#fff;
      transition: text-shadow .2s;
  }
  .lostPassword:hover {
      color: #ffffff !important;
      text-shadow: 2px -1px 13px #fff;
      text-decoration: none;
  }


  .lostPasswordWindow{
      padding-bottom:0px;

  }
  .editLostPassword{
      max-width:395px;
      padding:2px 12px !important;
  }
  .lostPasswordInfo {
      font-size: 17px;
      line-height: 25px;
      color: #555555;
  }  
  
  .loginAdditionalWrapper{
      color:#fff;
  }

}
.g-recaptcha-wrapper{
    position: relative;
    display: block;
    height:65px;
}
.g-recaptcha{
    transform:scale(0.77);
    -webkit-transform:scale(0.77);
    transform-origin:0 0;
    -webkit-transform-origin:0 0; 
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    right: 50%;
    margin: auto;       
}


@media screen and (max-height: 640px) {
    .overlayID .loginForm .loginFormElements {
        position: relative;
        width: 320px !important;

        margin-top: 30px;
    }
}

@media screen and (max-height: 600px) {
    .overlayID .loginForm .loginFormElements {
        position: relative;
        width: 300px !important;

        margin-top: 30px;
    }
}


@media screen and (max-height: 480px) {
    .overlayID .loginForm .loginFormElements {
        position: relative;
        width: 300px !important;

        margin-top: 30px;
    }
}

.passwordInfo{
    font-size:17px;
    color:#111;
}

.editEmail{
    margin-top:10px;
}