.bottom-line {
  width: 80px;
  height: 5px;
  background-color: #004288;
}
.serviceDetailsInfo{
  font-size:19px;
}
.su-color{
  color: #004288;
}
.btn-map-opener{
  display:block !important;
  width:150px;
  margin-left:auto;
  margin-right:auto;
  margin-top:20px;
  background-color: #004288 !important;
  border-color:#004288 !important;
  color:#fff !important;

  &:hover{
    background-color: #0351a4 !important;
    border-color:#0351a4 !important;
  }
}

.btn-diger-yolcular{
  display:block !important;
  width:150px;
  margin-top:20px;
  margin-right:10px;
  background-color: #004288 !important;
  border-color:#004288 !important;
  color:#fff !important;

  &:hover{
    background-color: #0351a4 !important;
    border-color:#0351a4 !important;
  }
}


.btn-sulink{
  display:inline-block !important;
  width:150px;
  margin-top:20px;
  background-color: #004288 !important;
  border-color:#004288 !important;
  color:#fff !important;

  &:hover{
    background-color: #0351a4 !important;
    border-color:#0351a4 !important;
  }
}

.margin-bottom-15{
  margin-bottom:15px;
}