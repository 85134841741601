.welcomeMessage{
  color:#fff;
}

@media screen and (max-height: 640px) {
  .overlayID .welcomeWrapper {
    max-width: 320px !important;

  }
  .overlayID .welcomeWrapper h1 {
    font-size:14px !important;
  }
}

@media screen and (max-height: 600px) {
  .overlayID .welcomeWrapper {
    max-width: 300px !important;

  }
  .overlayID .welcomeWrapper h1 {
    font-size:14px !important;
  }
}

@media screen and (max-height: 600px) {
  .overlayID .welcomeWrapper {
    max-width: 280px !important;

  }
  .overlayID .welcomeWrapper h1 {
    font-size:14px !important;
  }
}

