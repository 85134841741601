.adminServisList{
  .tableServisler td{
    vertical-align: middle !important;
  }
  
  .switch.btn{
    width:140px !important;
  }
  
  .servisTableWrapper{
  }
  
  .servisTable{
    overflow: hidden;
  }

  .servis-list{
    width:100%;
    height: calc(100vh - 165px) !important;
    background:white;    
  }

  .servis-list .react-bs-container-body {
    display: block;
    box-sizing: border-box;
    height: calc(100% - 55px) !important;
}  
  
}
.margin-bottom-0{
  margin-bottom:0px !important;
}

.basdate-wrapper{
  margin-left:40px;
}

.basdate-label{
  margin-right:5px;
  font-weight: bold;
  color:#004288 ;
}

.bitdate-label{
  margin-left:10px;
  margin-right:5px;
  font-weight: bold;
  color:#004288 ;
}
.btnalonesearch{
  height: 40px;
  margin-top: 7px;
  color:#004288 ;
}